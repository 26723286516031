@charset 'UTF-8';



.twitter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    padding: 15px;
    border: 1px solid #71767b;
    border-radius: 12px;
    color: #fff;
    width: 200px;
    height: 300px;
    text-align: center;

    &__inner {
        width: 100%;
    }

    &__title {
        padding-bottom: 5px;
        font-size: 14px;
    }

    &__button {
        display: block;
        padding: 10px;
        border-radius: 100px;
        background-color: #1d9bf0;
        color: #fff !important;
        font-size: 12px;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}